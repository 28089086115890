import axios from 'axios';
import Store from './store'

const api = (method, resource, data, addlHeaders={}) => {
  const client = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    json: true
  });

  if( Store.state.token ) {
    addlHeaders['Authorization'] = `Bearer ${Store.state.token}`;
  }

	return client({
		method,
		url: resource,
		data,
		headers: {
			...addlHeaders
		}
	}).then( req=> {
		return req.data
	} )
};

export default {
  Setup: {
    from_slack(oauth_code) {
      return api('POST', '/setup/slack', {oauth_code})
    }
  },
  Contributions: {
    list_celebrations(page=1) {
      return api('GET', '/admin/contributions/celebrations?page=' + page)
    },
    get_celebration(id) {{
      return api('GET', '/admin/contributions/celebrations/' + id)
    }}
  },
  Statements: {
    get(id) {
      return api('GET', '/admin/statements/' + id)
    },
    list(page=1) {
      return api('GET', '/admin/statements?page=' + page)
    },
    init_payment(id) {
      return api('GET', '/admin/statements/'+id+'/init_payment')
    }
  },
  Chipin: {
    get_celebration(id) {
      return api('GET', '/chipin/celebration/' + id)
    },
    payment_intent(amount, celebration_id, pi) {
      if( pi === null ) return api('POST', '/chipin/payment_intent', {amount, celebration_id})

      return api('POST', '/chipin/payment_intent', {amount, pi_id: pi.id, celebration_id})
    }
  },
  Billing: {
    init_new_card() {
      return api('GET', '/admin/billing/setup_card')
    },
    get_payment() {
      return api('GET', '/admin/billing/payment_method')
    }
  },
  Team: {
    update_setting(key, value) {
      return api('PATCH', '/admin/team/settings', {
        [key]: value
      })
    },
    get_dashboard() {
      return api('GET', '/admin/team/dashboard');
    }
  },
  Users: {
    search(page=1, query='', filters={}) {
      return api('GET', '/admin/users/search?page='+page+'&filters='+JSON.stringify(filters)+'&q=' + query)
    },
    resolve_emails(emails) {
      return api('POST', '/admin/users/resolve_emails', {emails})
    },
    list(page=1) {
      return api('GET', '/admin/users/?page=' + page)
    },
    update(id, fields={}) {
      return api('PATCH', '/admin/users/' + id, fields);
    }
  },
  User: {
    me() {
      return api('GET', '/user/me')
    },
    slack_login(oauth_code) {
      return api('POST', '/auth/slack', {oauth_code})
    }
  },
  Groups: {
    list() {
      return api('GET', '/admin/groups')
    },
    get(id) {
      return api('GET', '/admin/groups/' + id)
    },
    create(group) {
      return api('POST', '/admin/groups', group)
    },
    update(group_id, group) {
      return api('PATCH', '/admin/groups/' + group_id, group)
    },
    archive(group_id) {
      return api('DELETE', '/admin/groups/' + group_id)
    },
    members(group_id) {
      return api('GET', '/admin/groups/' + group_id + '/members')
    }
  },
  Slack: {
    get_channels(user_id='') {
      if( user_id !== null ) return api('GET', '/admin/slack/channels?user_id=' + user_id);

      return api('GET', '/admin/slack/channels')
    },
    create_channel(name, type='PUBLIC', to_invite=[]) {
      return api('POST', '/admin/slack/channel', {name, type, to_invite})
    }
  }
};