<template>
  <div class="container" id="member-wrap">
    <view-wrap>
      <img src="../../assets/logo.svg" height="180" />
      <p>Hey, {{me.display_name}}! You're not an admin, so you'll want to use our app inside of Slack.</p>
    </view-wrap>
  </div>
</template>

<script>
export default {
  name: 'member-home',
  computed: {
    me() {
      return this.$store.state.user;
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 400px;
  margin: 100px auto 0;
  text-align: center;

  p {
    margin: 35px 0 0;
  }
}
</style>