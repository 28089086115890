<template>
  <view-wrap>
    <header>
      <h1>Contributions</h1>
    </header>

    <main>
      <template v-if="!!celebrations">
        <ul class="celebration-list" v-if="celebrations.length > 0">
          <li v-for="c in celebrations" :key="`contribution_${c.id}`">
            <router-link :to="{name: 'celebration-details', params: {celebrationId: c.id}}">
              <profile-picture :user="c.user" />
              <h4>
                <name :user="c.user" />&rsquo;s birthday! <small>{{formatDate(c.created_at)}}</small>
              </h4>

              <div class="status">
                <el-tooltip placement="top">
                  <div slot="content" v-html="statusText(c)"></div>
                  <fa-icon :icon="statusIcon(c)" />
                </el-tooltip>
              </div>

              <div class="amount">
                <span>{{$formatMoney(c.main_donation.currency_amount)}}</span>
              </div>

            </router-link>
          </li>
        </ul>
        <div class="empty-state" v-else>
          <h4>Goodness will show up here.</h4>
          <p>Once your team celebrates their first birthday, you'll see the donation here.</p>
        </div>
      </template>
      <loading-indicator color="dark" v-else />
    </main>
  </view-wrap>
</template>

<script>
import {DateTime} from 'luxon'
export default {
  name: 'contributions',
  head: {
    title: {
      inner: 'Contributions'
    }
  },
  data() {
    return {
      celebrations: null,
      page: 0,
      hasMorePages: true,
      populateCelebrations: _.debounce( this._populateCelebrations, 250 )
    }
  },
  watch: {
    page() {
      this.populateCelebrations()
    }
  },
  mounted() {
    this.page = 1;

    this.$root.$on('scrolledToBottom', _.debounce(this._handleScrollBottom, 250) )
  },
  methods: {
    _handleScrollBottom() {
      if( this.hasMorePages ) this.page ++;
    },
    async _populateCelebrations() {
      const resp = await this.$api.Contributions.list_celebrations(this.page)

      if( this.celebrations === null ) this.celebrations = [];
      
      this.celebrations.push(...resp);

      if( resp.length < 50 ) this.hasMorePages = false;
    },
    formatDate(date) {
      return DateTime.fromISO( date ).toLocaleString(DateTime.DATE_FULL)
    },
    statusText(c) {
      const {status} = c.main_donation.statuses[0];
      switch( status ) {
        case "PENDING":
          return 'This contribution is pending and will show up on your next statement.';
        break;
        case "PROCESSING":
          return `We're currently processing your most recent statement.`;
        break;
        case "COMPLETE":
          return `Your latest statement is closed and funds have been disbursed.`;
        break;
      }
    },
    statusIcon(c) {
      const {status} = c.main_donation.statuses[0];
      switch( status ) {
        case "PENDING":
          return 'clock';
        break;
        case "PROCESSING":
          return 'receipt';
        break;
        case "COMPLETE":
          return 'paper-plane';
        break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.celebration-list {
  padding: 10px 0;
  margin: 0;
  display: block;

  > li {
    display: block;
    padding: 10px 0;
    margin: 0;

    > a {
      display: flex;
      background: $white;
      border-radius: $border-radius;
      box-shadow: $block-shadow;
      padding: 25px 25px 25px 25px;
      text-decoration: none;
      color: $black;
      align-items: center;
      transition: transform 0.2s $curve;

      &:hover {
        transform: scale(1.015);
      }

      &:after {
        content: '';
      }

      h4 {
        font-weight: 700;
        flex: 1;
        margin: 0 0 0 8px;

        > small {
          font-weight: normal;
          font-size: 0.8em;
          color: #616161;
        }
      }

      .amount {
       //margin: 0 15px;

        > span {
          background: $green;
          border-radius: 100em;
          padding: 4px 10px;
          display: block;
          font-size: 14px;
        }
      }

      .status {
        color: #616161;
        cursor: help;
        margin: 0 10px 0 0;

        &:hover {
          color: $black;
        }
      }

      .user-count {
        display: flex;
        align-items: center;

        > span {
          font-size: 14px;
          margin: 0 0 0 5px;
        }

        .user-preview {
          display: flex;
          padding: 0 6px;

          > .profile-picture {
            margin: 0 -3px;
            box-shadow: #fff 0 0 0 2px;
            flex: 0 0 22px;
          }
        }
      }
    }
  }
}
</style>