<template>
  <view-wrap>
    <template v-if="loading === false && showInstall === true">
      <div class="container">
        <main>
          <div class="logo"></div>
          <h1>Hey! Let's get started.</h1>
          <p>Just some legal stuff and a quick prompt from Slack, and we'll have you on your way.</p>

          <div class="form-group">
            <label class="field-label">Where is your company located?</label>
            
            <el-radio-group v-model="location">
              <el-radio :label="'US'">We're located in the United States</el-radio>
              <el-radio :label="'ELSEWHERE'">We're located somewhere else</el-radio>
            </el-radio-group>
          </div>

          <div class="form-group">
            <label class="field-label">Have you read our <a href="https://www.givecupcake.com/privacy-policy" rel="noopener" target="_blank">Privacy Policy</a> and <a href="https://www.givecupcake.com/terms-of-service" rel="noopener" target="_blank">Terms of Service</a>?</label>
            <el-checkbox v-model="agree">Yes, I understand Cupcake is a product of HiThrive, Inc and agree to the Privacy Policy and Terms of Service.</el-checkbox>
          </div>

          <div class="form-group">
            <el-button type="primary" @click="doInstall" round :disabled="!canContinue">Continue</el-button>

            <p class="hint-text" v-if="location === 'ELSEWHERE'">Uh oh, because we only support donations to charities in the United States, you must also be located here to use Cupcake at this time.</p>
          </div>
        </main>
      </div>
    </template>
    
    <template v-else>
      <div class="container">
        <main>
          <div class="logo"></div>
          <loading-indicator color="dark" />
        </main>
      </div>
    </template>
  </view-wrap>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'slack-oauth',
  computed: {
    canContinue() {
      return this.agree === true && this.location === 'US';
    }
  },
  data() {
    return {
      showInstall: false,
      location: null,
      agree: false,
      loading: false
    }
  },
  watch: {
    '$route.query.code'(newVal, oldVal) {
      if( !newVal ) {
        this.showInstall = true;
      }
    }
  },
  mounted() {
    if( this.$route.query.code) {
      this.handleOAuthRedirect( this.$route.query.code, this.$route.query.state );
    }
    else {
      this.showInstall = true;
    }
  },
  methods: {
    doInstall() {
      const state = this.$generateToken(10);
      const exp = new Date( new Date().getTime() + (10*60000) );
      Cookies.set('cupcake_oauth_state', state, {expires: exp, domain: process.env.VUE_APP_CUPCAKE_DOMAIN})

      window.location = `${process.env.VUE_APP_SLACK_INSTALL_URI}&state=${state}`;
    },
    async handleOAuthRedirect(code, state) {
      const savedState = Cookies.get('cupcake_oauth_state');
      if( state !== savedState ) {
        this.$message({
          type: 'error',
          message: `Hm, something fishy happened. Please try again.`
        })

        return false;
      }

      this.$router.replace({query: {code: undefined}});
      this.loading = true;
      const resp = await this.$api.Setup.from_slack( code ).catch( e => {
        this.$message({
          type: 'error',
          message: e.response.data.message
        })

        return false;
      } )

      if( resp === false ) {
        this.loading = false;
        return;
      }

      this.$store.commit('token', resp.token);
      await this.$store.dispatch('populateUser');

      dataLayer.push({'event': 'app_install'});

      this.loading = false;

      this.$router.replace({name: 'admin-dashboard'})
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  background: $white;
  border-radius: $border-radius;
  box-shadow: $block-shadow;
  display: block;
  flex: 1;
  padding: 35px;
  max-width: 512px;
  margin: 125px auto 0;
  position: relative;

  .spinner {
    margin: 40px auto;
  }

  .form-group {
    margin: 25px 0 0;
    > .field-label {
      font-weight: 700;
      margin: 0 0 5px;
      display: block;
    }

    .el-button {
      display: block;
      width: 100%;
      margin: 35px 0 0;
    }

    .el-radio, .el-checkbox {
      font-size: 16px;
      margin: 4px 0 0;

      ::v-deep .el-radio__label,
      ::v-deep .el-checkbox__label {
        font-size: 16px;
        color: $black;
        white-space: normal;
      }

      ::v-deep .el-checkbox__input {
        vertical-align: top;
        margin: 5px 0 0;
      }
    }
  }

  .logo {
      position: absolute;
      display: block;
      left: 15px;
      width: 120px;
      top: -100px;
      height: 100px;
      background-image: url('../../assets/half-logo.svg');
      background-size: auto 90px;
      background-position: left bottom;
      background-repeat: no-repeat;
      z-index: 0;
    }

  h1 {
    font-weight: 700;
    font-size: 24px;
  }

  p {
    margin: 15px 0 0;
    line-height: 1.25em;
    font-size: 18px;
  }
}
</style>