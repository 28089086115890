<template>
  <view-wrap>
    <main>
      <section>
        <div class="logo"></div>

        <div class="inner">
          <template v-if="celebration !== null">
            <h1>Make a Donation</h1>
            <h2><profile-picture :user="celebration.user" height="22" /> {{celebration.user.name}} is celebrating their birthday by raising money for <strong>{{celebration.charity.name}}</strong> in {{celebration.charity.city}}, {{celebration.charity.state}}.</h2>
            <template v-if="success == false">
              <p class="block">Your company has donated {{$formatMoney(celebration.main_donation_amount)}}.</p>
              <p class="block">You can pitch in by making your own donation.<small>Tip: your name and amount pitched in is not visible to your team members</small></p>

              <div class="field">
                <label>Donation amount:</label>
                <ul class="amounts">
                  <li>
                    <a href="#" @click.prevent="presetAmount=5" :class="[{'active': presetAmount === 5}]">$5</a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="presetAmount=10" :class="[{'active': presetAmount === 10}]">$10</a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="presetAmount=25" :class="[{'active': presetAmount === 25}]">$25</a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="presetAmount=50" :class="[{'active': presetAmount === 50}]">$50</a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="presetAmount=100" :class="[{'active': presetAmount === 100}]">$100</a>
                  </li>
                  <li>
                    <currency-input class="form-control" v-model.number="customAmount" :currency="team.currency_code" :precision="0" :distraction-free="false" :value-as-integer="true" :allow-negative="false" />
                  </li>
                </ul>
              </div>

              <div class="field">
                <label>Credit/debit card:</label>
                <div ref="stripeWrapper" class="stripe-wrapper">
                  <fa-icon spin icon="sync" class="stripe-loader" />
                </div>
              </div>

              <div class="field submit-btn">
                <el-button type="success" :disabled="!canDonate" :loading="loading" @click="doDonation">Donate {{$formatMoney(donationAmount)}}</el-button>

                <p class="disclaimer">
                  By submitting your donation, you agree to HiThrive, Inc (dba Cupcake)&rsquo;s <a href="https://www.givecupcake.com/privacy-policy" target="_blank" rel="noopener">Privacy Policy</a> and <a href="https://www.givecupcake.com/terms-of-service" target="_blank" rel="noopener">Terms of Service</a>.
                </p>
              </div>
            </template>
            <template v-else>
              <div class="success">
                <fa-icon class="success-mark" icon="check-circle" />
                <h3>You did it!</h3>
                <p>Thanks for pitching in, you're amazing! Your donation is currently being processed.</p>

                <p class="hint-text">We disburse funds to charities at the end of every month. Depending on when your payment clears, it may take up to 30 days before {{celebration.charity.name}} receives your contribution.</p>
              </div>
            </template>
          </template>
          <loading-indicator color="dark" v-else-if="error === false" />
          <div v-else>
            <el-alert title="We ran into an error loading this donation, please try again." :closable="false" type="error" show-icon />
          </div>
        </div>
      </section>
    </main>
  </view-wrap>
</template>

<script>
import {CurrencyInput} from 'vue-currency-input'

export default {
  name: 'donate',
  head: {
    title: {
      inner: 'Make a Donation'
    }
  },
  components: {
    CurrencyInput
  },
  computed: {
    me() {
      return this.$store.state.user;
    },
    team() {
      return this.$store.state.team;
    },
    donationAmount() {
      return Math.max(0, this.customAmount !== 0 ? this.customAmount : this.presetAmount);
    },
    canDonate() {
      return this.donationAmount >= 1;
    }
  },
  watch: {
    customAmount(v) {
      if( v !== 0 ) this.presetAmount = -1;
    },
    presetAmount(v) {
      if( v !== -1 ) this.customAmount = 0;
    },
    donationAmount(v) {
      this.genPaymentIntent()
    }
  },
  data() {
    return {
      celebration: null,
      error: false,
      presetAmount: 0,
      customAmount: 0,
      loading: false,
      stripeCard: null,
      renderStripeElement: _.debounce( this._renderStripe, 250 ),
      paymentIntent: null,
      genPaymentIntent: _.debounce( this._genPaymentIntent, 250 ),
      success: false
    }
  },
  mounted() {
    this.populateCelebration( this.$route.params.celebrationId )
    this.renderStripeElement();

    this.presetAmount = 5;
  },
  methods: {
    async _genPaymentIntent() {
      this.loading = true;
      const resp = await this.$api.Chipin.payment_intent( this.donationAmount * 100, this.celebration.id, this.paymentIntent )
      this.paymentIntent = resp;
      this.loading = false;
    },
    _renderStripe() {
      const elements = this.$stripe.elements();

      this.stripeCard = elements.create("card")
      this.stripeCard.mount( this.$refs.stripeWrapper )
    },
    async populateCelebration(id) {
      try {
        const resp = await this.$api.Chipin.get_celebration( id );

        this.celebration = resp;
      } catch(e) {
        this.error = true;
      }
    },
    async doDonation() {
      this.loading = true;
      const {error, paymentIntent} = await this.$stripe.confirmCardPayment(this.paymentIntent.client_secret, {
        payment_method: {
          card: this.stripeCard,
        }
      })
      this.loading = false;

      if( error ) {
        this.$message({
          type: 'error',
          message: error.message
        })
        return false;
      }

      this.success = true;
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  max-width: 600px;
  margin: 0 auto;
  padding: 35px 15px;

  ::v-deep .spinner {
    margin: 55px auto;
  }

  > section {
    background: $white;
    border-radius: $border-radius;
    box-shadow: $block-shadow;
    position: relative;
    min-height: 100px;
    margin: 100px 0 0;
    z-index: 1;
    padding: 35px;
    color: $black;

    .success {
      text-align: center;
      margin: 25px 0 0;

      .success-mark {
        color: #659232;
        font-size: 42px;
      }

      h3 {
        color: #659232;
        font-size: 22px;
        font-weight: 700;
        margin: 15px 0 0;
      }

      p {
        margin: 15px 0;
      }
    }

    h1 {
      font-weight: 700;
      font-size: 26px;
    }

    h2 {
      font-size: 18px;
      margin: 15px 0 15px;
      line-height: 1.35em;

      .profile-picture {
        display: inline-block;
        vertical-align: -4px;
        margin: 0 2px 0 0;
      }
    }

    .field {
      margin: 15px 0 0;
      > label {
        display: block;
        font-weight: 700;
        margin: 0 0 5px;
      }

      &.submit-btn {
        margin: 35px 0 0;
      }

      .el-button--success {
        display: block;
        width: 100%;
        font-size: 18px;
      }
    }

    .stripe-loader {
      color: #ccc;
      display: block;
      margin: 0 auto;
    }

    .stripe-wrapper.StripeElement {
      border: 1px solid #DCDFE6;
      border-radius: 4px;
      padding: 10px;

      &.StripeElement--focus {
        border: 1px solid #409EFF;
      }
    }

    p {
      &.disclaimer {
        margin: 15px 0 0;
        color: #747474;
        font-size: 14px;

        a {
          text-decoration: none;
        }
      }

      &.block {
        display: block;
        padding: 2px 0 2px 15px;
        margin: 0 0 10px;
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          border-radius: 100em;
          width: 4px;
          background: #d8d8d8;
        }

        > small {
          display: block;
          color: #949494;
          font-size: 0.7em;
          font-style: italic;
          margin: 2px 0 0;
        }
      }
    }

    .amounts {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;

      > li {
        display: block;
        flex: 0 0 50%;
        @include lg {
          flex: 1;
        }
        padding: 5px;

        > a {
          display: block;
          border-radius: 4px;
          box-shadow: $blue 0 0 0 1px;
          text-align: center;
          padding: 10px;
          color: $black;
          text-decoration: none;

          &.active {
            background: $red;
            color: #fff;
            box-shadow: $red 0 0 0 1px, rgba(0,0,0,0.1) 0 5px 8px;
          }
        }
      }
    }

    .logo {
      position: absolute;
      display: block;
      left: 15px;
      width: 120px;
      top: -100px;
      height: 100px;
      background-image: url('../assets/half-logo.svg');
      background-size: auto 90px;
      background-position: left bottom;
      background-repeat: no-repeat;
      z-index: 0;
    }
  }
}
</style>