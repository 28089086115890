<template>
  <view-wrap>
    <header>
      <h1>Users</h1>

      <div class="controls">
        <span class="sync-indicator">
          Synced with Slack
        </span>
      </div>
    </header>

    <main>
      <section class="user-table">
        <header class="filter-wrap">
          <div class="search-input-wrap">
            <input type="text" v-model="query" placeholder="search by name..." />
          </div>
          <div class="filter-by">
            <el-dropdown>
              <span class="el-dropdown-link">
                Filter by <fa-icon icon="angle-down" />
              </span>
              <el-dropdown-menu>
                <div class="filter-by-dropdown">
                  <ul>
                    <li>
                      <el-checkbox v-model="filters.admins">Administrators</el-checkbox>
                    </li>
                    <li>
                      <el-checkbox v-model="filters.no_group">Users without a group</el-checkbox>
                    </li>
                    <li>
                      <el-checkbox v-model="filters.no_charity">Users without a charity</el-checkbox>
                    </li>
                    <li>
                      <el-checkbox v-model="filters.no_birthday">Users without a birthday</el-checkbox>
                    </li>
                  </ul>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="filter-by" v-if="groups.length > 0">
            <el-dropdown>
              <span class="el-dropdown-link">
                In group <fa-icon icon="angle-down" />
              </span>
              <el-dropdown-menu>
                <div class="filter-by-dropdown">
                  <el-radio-group v-model="filters.group_id">
                    <ul>
                      <li>
                        <el-radio :label="null">Any group</el-radio>
                      </li>
                      <li v-for="group in groups" :key="`group_${group.id}`">
                        <el-radio :label="group.id">{{group.name}}</el-radio>
                      </li>
                    </ul>
                  </el-radio-group>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </header>

        <template v-if="users !== null">
          <ul v-if="users.length > 0">
            <li v-for="user in users" :key="`user_${user.id}`">
              <router-link :to="{name: 'user-details', params: {userId: user.id, data: user}}">
                <div class="name">
                  <profile-picture :user="user" />
                  <name :user="user" full />
                </div>

                <div class="tags">
                  <span class="danger" v-if="_.isEmpty(user.charity_details)">No charity</span>
                  <span class="danger" v-if="user.birthday_month === null">Need birthday</span>
                </div>

                <div class="date">
                  <span class="bday" v-if="user.birthday_month"><fa-icon icon="birthday-cake" /> {{birthday(user)}}</span>
                </div>
              </router-link>
            </li>
          </ul>
          <div class="empty-state" v-else>Ope! No users were found.</div>
        </template>

        <loading-indicator color="dark" v-else />
      </section>
    </main>
  </view-wrap>
</template>

<script>
import {DateTime} from 'luxon'
export default {
  name: 'users',
  head: {
    title: {
      inner: 'Users'
    }
  },
  computed: {
    groups() {
      return this.$store.state.groups;
    }
  },
  data() {
    return {
      users: null,
      page: 0,
      populateUsers: _.debounce( this._populateUsers, 250 ),
      hasMorePages: true,
      query: '',
      filters: {
        admins: false,
        no_group: false,
        no_charity: false,
        no_birthday: false,
        group_id: null
      }
    }
  },
  watch: {
    '$route': function(newVal, oldVal) {
      if( newVal.params.forceRefresh === true ) {
        this.page = 1;
        this.users = null;
        this.populateUsers();
      }
    },
    page() {
      this.populateUsers()
    },
    query() {
      this.page = 1;
      this.users = null;
      this.populateUsers()
    },
    filters: {
      deep: true,
      handler() {
        this.page = 1;
        this.users = null;
        this.populateUsers()
      }
    }
  },
  mounted() {
    this.page = 1;
    this.users = null;

    this.$store.dispatch('populateGroups');
    this.$root.$on('scrolledToBottom', _.debounce(this._handleScrollBottom, 250) )
  },
  methods: {
    _handleScrollBottom() {
      if( this.hasMorePages ) this.page ++;
    },
    async _populateUsers() {
      const resp = await this.$api.Users.search(this.page, this.query, this.filters)

      if( this.users === null ) this.users = [];
      
      this.users.push( ...resp )

      if( resp.length < 50 ) this.hasMorePages = false;
    },
    birthday(u) {
      return DateTime.fromObject({month: u.birthday_month, day: u.birthday_date}).toFormat('MMM dd')
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-by-dropdown {
  padding: 10px 15px;

  ul {
    display: block;

    > li {
      display: block;
      padding: 5px;
    }
  }
}

.sync-indicator {
  color: #659e09;
  font-size: 12px;
  position: relative;
  padding: 0 0 0 12px;
  display: block;

  &:before {
    content: '';
    display: block;
    background-color: #659e09;
    border-radius: 100%;
    width: 6px;
    height: 6px;
    animation: blip 1s linear infinite;
    position: absolute;
    left: 0;
    top: 6px;
  }
}

main {
  .spinner {
    margin: 50px auto;
  }
  > section {
    margin: 20px 0 0;
    background: $white;
    border-radius: $border-radius;
    box-shadow: $block-shadow;
    padding: 25px 25px 25px 25px;

    .filter-wrap {
      display: flex;
      margin: -25px -25px 15px;
      border-bottom: 1px solid #e7ded5;

      > .search-input-wrap {
        flex: 1;

        > input {
          appearance: none;
          background: none;
          border: none;
          outline: none;
          font-size: 18px;
          width: 100%;
          display: block;
          padding: 25px;
        }
      }

      > .filter-by {
        padding: 25px;

        .el-dropdown-link {
          cursor: pointer;
        }
      }
    }

    ul {
      padding: 0;
      margin: 0 -25px 0 0;

      > li {
        display: block;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid #e7ded5;

        > a {
          display: flex;
          padding: 10px 25px 10px 5px;
          color: $black;
          text-decoration: none;
          align-items: center;

          .name {
            display: flex;
            align-items: center;

            > span {
              margin: 0 0 0 10px;
            }
          }

          .date {
            margin: 0 0 0 auto;
          }

          .tags {
            padding: 0 5px;
            > span {
              margin: 0 3px;
              padding: 2px 5px;
              border-radius: 100em;
              display: inline-block;
              font-size: 11px;
              background: #e7ded5;
              line-height: 12px;
              
              &.danger {
                background: $red;
                color: #fff;
              }
            }
          }

          &:hover {
            background: #f7f7f7;
          }
        }
      }
    }
  }
}

@keyframes blip {
  0% {
    box-shadow: rgba(#659e09, 0) 0 0 0 0;
  }
  50% {
    box-shadow: rgba(#659e09, 0.3) 0 0 0 3px;
  }
  100% {
    box-shadow: rgba(#659e09, 0) 0 0 0 6px;
  }
}
</style>