<template>
  <div v-bind:class="['profile-picture', {'can-click': this.hasClick || this.hoverState}]" v-bind:style="[{'width': size.width, 'height': size.height, 'background-image': src, 'cursor': (this.hasClick ? 'pointer' : 'inherit')}]" v-on:click="handleClick">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ProfilePicture',
  props: ['user', 'width', 'height', 'hoverState'],
  computed: {
    hasClick() {
      return 'click' in this.$listeners;
    },
    src() {
      let user = null;
      if( typeof this.user == 'undefined' ) {
        //use current user
        user = this.$store.state.user;
      }
      else if( typeof this.user == 'object' ) {
        user = this.user;
      }

      let src = null;
      if( user ) {
        src = user.profile_picture;
      }

      return `url('${src || `https://img-cdn.hithrive.com/100x100/default_cat.png`}')`;
    },
    size() {
      if( this.width && !this.height ) { //No height, just width
        return {
          width: this.width + 'px',
          height: this.width + 'px',
        }
      }
      if( this.height && !this.width ) { //No width, just height
        return {
          width: this.height + 'px',
          height: this.height + 'px',
        }
      }
      if( this.width && this.height ) { //Both height & width
        return {
          width: this.width + 'px',
          height: this.height + 'px',
        }
      }

      return { //Nothing set
        width: '35px',
        height: '35px'
      }
    }
  },
  methods: {
    handleClick(e) {
      this.$emit( 'click', e )
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.profile-picture {
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  border-radius: 100%;
  position: relative;
  overflow: hidden;

  &.can-click {
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0,0,0,0.2);
      opacity: 0;
      transition: opacity $curve 0.5s;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
}
</style>
