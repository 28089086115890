<template>
  <view-wrap>
    <header>
      <h1>Settings</h1>
    </header>

    <main v-if="!!team">
      <section class="settings-section">
        <h2>Celebrations</h2>

        <div class="inner">

          <div class="setting">
            <div class="label">
              <label>Weekend Birthdays</label>
              <p>Choose when your team should celebrate birthdays that fall on Saturdays or Sundays.</p>
            </div>

            <div class="field">
              <el-select v-model="team.settings.celebrate_weekend" @change="updateSetting('celebrate_weekend')">
                <el-option value="NORMALLY" label="Celebrate on weekends" />
                <el-option value="FRIDAY" label="Celebrate on Fridays" />
                <el-option value="MONDAY" label="Celebrate on Mondays" />
              </el-select>
            </div>
          </div>

          <div class="setting">
            <div class="label">
              <label>Pitch in Contributions</label>
              <p>Allow team members to make additional contributions to celebrations using their own money.</p>
            </div>

            <div class="field">
              <el-select v-model="team.settings.disable_chipin" @change="updateSetting('disable_chipin')">
                <el-option :value="false" label="Allow pitch in contributions" />
                <el-option :value="true" label="Disable pitch in contributions" />
              </el-select>
            </div>
          </div>

        </div>
      </section>

      <billing-settings />
    </main>
  </view-wrap>
</template>

<script>
import BillingSettings from '@/components/Settings/Billing';

export default {
  name: 'settings',
  components: {
    BillingSettings
  },
  head: {
    title: {
      inner: 'Settings'
    }
  },
  computed: {
    _team() {
      return this.$store.state.team;
    }
  },
  data() {
    return {
      team: null,
      updateSetting: _.debounce(this._updateSetting, 250)
    }
  },
  mounted() {
    this.team = _.cloneDeep(this._team);
  },
  methods: {
    async _updateSetting(key) {
      await this.$api.Team.update_setting(key, this.team.settings[key])
      this.$store.dispatch('updateTeamSetting', {
        [key]: this.team.settings[key]
      })
      this.$message({
        type: 'success',
        message: 'Setting has been saved.'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-section {
  margin: 20px 0 0;
  background: $white;
  border-radius: $border-radius;
  box-shadow: $block-shadow;
  padding: 25px 25px 25px 25px;

  ::v-deep h2 {
    font-weight: 700;
    font-size: 20px;
    margin: -25px -25px 25px;
    padding: 25px;
    border-bottom: 1px solid #e7ded5;
  }
}
</style>