<template>
  <div :class="['spinner', color]">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</template>

<script>
export default {
  props: ['color']
}
</script>

<style lang="scss" scoped>
.spinner {
  margin: 100px auto 0;
  width: 78px;
  text-align: center;

  > div {
    width: 18px;
    height: 18px;
    background-color: $white;
    margin: 0 3px;

    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1s infinite ease-in-out both;
  }

  &.dark {
    > div {
      background-color: $black;
    }
  }
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%, 100% { 
    transform: scale(0.5);
  } 30% { 
    transform: scale(1.0);
  }
}
</style>