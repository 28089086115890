<template>
  <view-wrap class="login-wrap">
    <div class="left">
      <template v-if="showLogin">
        <div class="login-window">
          <h1>Welcome back</h1>
          <p>Use your Slack account to login to Cupcake.</p>
          <el-alert type="error" v-if="!!error" :title="error" show-icon :closable="false" />
          <div class="btn-wrap">
            <a :href="loginURI"><img alt="Sign in with Slack" height="40" width="172" src="https://platform.slack-edge.com/img/sign_in_with_slack.png" srcset="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x" /></a>
          </div>
        </div>
      </template>
      <template v-else>
        <loading-indicator color="dark" />
      </template>
    </div>
    <div class="right">
      <div class="inner">
        <img src="../assets/logo.svg" width="140" />
        <h2>Cupcake is here to make your birthday less about you.<small>(and more about giving...)</small></h2>
        <ul>
          <li>Cupcake collects &amp; celebrates birthdays on Slack.</li>
          <li>Donate $1 to $1,000 to the charity your team members choose.</li>
          <li>Consolidated billing for little administration burden.</li>
        </ul>

        <el-button type="primary" @click="goSignup" round>Sign up</el-button>
      </div>
    </div>
  </view-wrap>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'login',
  data() {
    return {
      showLogin: false,
      error: null,
      loginURI: ''
    }
  },
  watch: {
    '$route'() {
      this.handleRoute()
    }
  },
  mounted() {
    this.$store.dispatch('logout')
    
    this.handleRoute();
  },
  methods: {
    goSignup() {
      this.$router.push({name: 'setup-slack-oauth'})
    },
    handleRoute() {
      if( this.$route.query.code ) {
        this.handleSlackLogin( this.$route.query.code, this.$route.query.state )
      }
      else {
        if( this.$route.query.continue ) {
          localStorage.setItem('cupcake_auth_continue', this.$route.query.continue)
        }
        else {
          localStorage.removeItem('cupcake_auth_continue')
        }

        if( this.$route.params.error ) this.error = this.$route.params.error;

        const state = this.$generateToken(10);
        const exp = new Date( new Date().getTime() + (10*60000) );
        Cookies.set('cupcake_oauth_state', state, {expires: exp, domain: process.env.VUE_APP_CUPCAKE_DOMAIN})

        this.loginURI = `https://slack.com/oauth/v2/authorize?user_scope=identity.basic&client_id=${process.env.VUE_APP_SLACK_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_CUPCAKE_URI}login&state=${state}`;

        this.showLogin = true;
      }
    },
    async handleSlackLogin(code, state) {
      const savedState = Cookies.get('cupcake_oauth_state');
      if( state !== savedState ) {
        this.$message({
          type: 'error',
          message: `Hm, something fishy happened. Please try again.`
        })

        return false;
      }

      try {
        const resp = await this.$api.User.slack_login( code );

        this.$store.commit('token', resp.token);

        await this.$store.dispatch('populateUser');

        const cont = localStorage.getItem('cupcake_auth_continue') || '/';
        this.$router.replace( decodeURIComponent(cont) )
      } catch(e) {
        if( e.response.data.type == 'NOT_INSTALLED' ) {
          this.$router.replace({name: 'login', params: {error: `Cupcake isn't installed on your workspace. Sign up to get started!`}})
          return;
        }

        this.$router.replace({name: 'login', params: {error: 'An unknown error occurred, please try again.'}})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrap {
  @include lg {
    display: flex;
    height: 100vh;
  }
  

  .left {
    background: $white;
    flex: 0 0 45%;
    padding: 100px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid #e6e0d5;

    @include lg {
      padding: 35px;
    }

    .el-alert {
      margin: 0 0 25px;
    }
    
    .btn-wrap {
      //text-align: center;
    }

    .login-window {
      max-width: 450px;
      width: 100%;
      margin: 0 auto;
      h1 {
        font-weight: 700;
        font-size: 26px;
        line-height: 26px;
        margin: 0 0 5px;
        color: $red;

        &+p {
          margin: 0 0 35px;
        }
      }
    }
  }

  .right {
    flex: 0 0 55%;
    padding: 35px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >.inner {
      max-width: 550px;
      width: 100%;
      margin: 0 auto;
    }

    img {
      transform: translateX(-40px);
    }

    h2 {
      font-size: 32px;
      line-height: 1.25em;
      margin: 15px 0 0;
      font-weight: 700;

      > small {
        display: block;
        font-size: 18px;
        font-weight: 700;
        font-style: italic;
        color: #b1a794;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }

    ul {
      list-style: disc;
      margin: 15px 0 25px;
      > li {
        font-size: 18px;
        &+li {
          margin: 10px 0 0;
        }
      }
    }
  }
}
</style>