<template>
  <view-wrap>
    <header>
      <h1>Groups</h1>

      <div class="controls">
        <el-button round size="mini" type="primary" @click="$router.push({name: 'create-group'})">Add Group</el-button>
      </div>
    </header>

    <main>
      <template v-if="groups !== null">
        <ul class="group-list" v-if="groups.length > 0">
          <li v-for="group in groups" :key="`group_${group.id}`">
            <router-link :to="{name: 'group-details', params: {groupId: group.id, data: group}}">
              <h4>{{group.name}}</h4>

              <div class="donation-amount">
                <span>{{$formatMoney(group.reward_amount)}}</span>
              </div>

              <div class="user-count">
                <template v-if="group.status === 'SYNCED'">
                  <div class="user-preview" v-if="group.users.length > 0">
                    <profile-picture v-for="user in group.users" :user="user" :key="`user_preview_${user.id}`" height="22" />
                  </div>
                  <span>{{$formatNumber(group.user_count)}} users</span>
                </template>
                <template v-else>
                  <fa-icon class="syncing" icon="sync" spin />
                </template>
              </div>
            </router-link>
          </li>
        </ul>
        <div class="empty-state" v-else>
          <h4>It all starts with a group.</h4>
          <p>Use groups to start celebrating birthdays in a channel on Slack.</p>
        </div>
      </template>

      <loading-indicator v-else color="dark" />
    </main>
  </view-wrap>
</template>

<script>
export default {
  name: 'groups',
  head: {
    title: {
      inner: 'Groups'
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    team() {
      return this.$store.state.team;
    },
    groups() {
      return this.$store.state.groups;
    },
    hasSyncingGroup() {
      if( !this.groups || this.groups.length == 0 ) return false;

      return _.findIndex(this.groups, {status: 'SYNCING'}) > -1;
    }
  },
  watch: {
    '$route': function(newVal, oldVal) {
      console.log(newVal, this.team.settings)
      if( newVal.params.forceRefresh === true ) {
        this.populateGroups();
      }

      if( newVal.params.action === 'CREATE' ) {
        if( newVal.params.isFirstGroup === true && this.team.settings.setup_payment !== true ) {
          //havent setup payment, first time creating group
          this.$root.$emit('openDialog', () => import('@/components/Modals/SavePaymentMethod'), {
            addPaymentReason: `Nice work! Your first group is all set.`
          }, (res) => {
            
          })
        }
      }
    },
    hasSyncingGroup(val) {
      if( !!this.syncingCheck) clearInterval( this.syncingCheck );

      if( val === true ) {
        this.syncingCheck = setInterval( this.populateGroups, 4000 );
      }
    }
  },
  data() {
    return {
      syncingCheck: null
    }
  },
  mounted() {
    this.populateGroups();
  },
  methods: {
    async populateGroups() {
      this.$store.dispatch('populateGroups');
    }
  }
}
</script>

<style lang="scss" scoped>
.group-list {
  padding: 10px 0;
  margin: 0;
  display: block;

  > li {
    display: block;
    padding: 10px 0;
    margin: 0;

    > a {
      display: flex;
      background: $white;
      border-radius: $border-radius;
      box-shadow: $block-shadow;
      padding: 25px 25px 25px 25px;
      text-decoration: none;
      color: $black;
      align-items: center;
      transition: transform 0.2s $curve;

      .syncing {
        font-size: 12px;
        color: rgb(129, 129, 129);
      }

      &:hover {
        transform: scale(1.015);
      }

      &:after {
        content: '';
      }

      h4 {
        font-weight: 700;
        flex: 1;
      }

      .donation-amount {
        margin: 0 15px;

        > span {
          background: $green;
          border-radius: 100em;
          padding: 4px 10px;
          display: block;
          font-size: 14px;
        }
      }

      .user-count {
        display: flex;
        align-items: center;

        > span {
          font-size: 14px;
          margin: 0 0 0 5px;
        }

        .user-preview {
          display: flex;
          padding: 0 6px;

          > .profile-picture {
            margin: 0 -3px;
            box-shadow: #fff 0 0 0 2px;
            flex: 0 0 22px;
          }
        }
      }
    }
  }
}
</style>