<template>
  <view-wrap class="disbursement-dashboard">
    <header>
      <h1>Donation Disbursement System</h1>

      <div class="login-wrap" v-if="data === null">
        <input type="text" class="form-control" v-model="password" placeholder="Enter password...">
        <el-button type="primary" @click="loadData">Login</el-button>
      </div>
    </header>

    <main v-if="data !== null">
      <div class="charity-list">
        <div class="charity" v-for="charity in data" :key="`charity_group_${charity.charity_ein}`">
          <header>
            <h2>{{charity.charity_details.name}} <small>{{$formatEin(charity.charity_ein)}}</small></h2>
          </header>

          <h4>Donations:</h4>
          <ul>
            <li v-for="donation in charity.donations" :key="`cg_donations_${charity.charity_ein}_${donation.id}`">
              <span class="timestamp">{{timestamp(donation.created_at)}}</span>
              <strong>{{donation.type === 'CHIP_IN' ? 'Chip-in Donation' : 'Birthday Celebration'}}</strong>
              <span class="amount">{{$formatMoney(donation.currency_amount)}}</span>
            </li>
          </ul>

          <footer>
            <div class="update-field">
              <input type="text" class="form-control" v-model="charity.disbursement_id" placeholder="Enter disbursement ID...">
              <el-button type="primary" @click="disburse(charity)" :loading="charity.loading || false">Submit</el-button>
            </div>
            <div class="total-amount">
              {{$formatMoney(charity.donation_total, '0,0.00')}}
            </div>
          </footer>
        </div>
      </div>
    </main>
  </view-wrap>
</template>

<script>
import axios from 'axios'
import {DateTime} from 'luxon'

export default {
  name: 'disbursement-dashboard',
  head: {
    title: {
      inner: 'Disbursement Admin'
    }
  },
  data() {
    return {
      data: null,
      password: ''
    }
  },
  methods: {
    timestamp(time) {
      return DateTime.fromISO(time).toLocaleString( DateTime.DATE_MED )
    },
    async loadData() {
      const resp = await axios.get(`${process.env.VUE_APP_API_URL}/disbursement`, {
        headers: {
          'X-CDS-Password': this.password
        }
      }).then( d => d.data );

      this.data = resp;
    },
    async disburse(charity) {
      this.$set(charity, 'loading', true)
      await axios.post(`${process.env.VUE_APP_API_URL}/disbursement/disburse`, {
        donations: _.map(charity.donations, 'id'),
        disbursement_id: charity.disbursement_id
      }, {
        headers: {
          'X-CDS-Password': this.password
        }
      }).then( d => d.data );
      this.$set(charity, 'loading', false)

      const i = this.data.findIndex((el) => el.charity_ein === charity.charity_ein);
      this.data.splice(i, 1);
    }
  }
}
</script>

<style lang="scss" scoped>
.disbursement-dashboard {
  max-width: 1024px;
  margin: 0 auto;
  padding: 25px 15px;

  > header {
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    margin: 0 0 25px;
    display: flex;
    align-items: center;

    > h1 {
      font-weight: 700;
      font-size: 18px;
    }

    > .login-wrap {
      display: flex;
      margin: 0 0 0 auto;
      
      > input {
        margin: 0 5px 0 0;
      }
    }
  }

  .charity-list {
    > .charity {
      background: #fff;
      border-radius: 12px;
      padding: 15px;

      > header {
        > h2 {
          font-weight: 700;
          margin: 0 0 10px;

          > small {
            font-size: 12px;
            font-weight: normal;
            font-style: italic;
          }
        }
      }

      > h4 {
        font-size: 12px;
        font-weight: 700;
      }

      > ul {
        > li {
          display: flex;
          margin: 0 -15px;
          padding: 5px 5px;
          font-size: 14px;
          border-bottom: 1px solid #f1f1f1;

          > span,
          > strong {
            display: block;
            padding: 0 10px;
          }

          > strong {
            flex: 1 0 0;
          }

          > .amount {
            background: $green;
            font-weight: 700;
            border-radius: 100em;
            width: 75px;
            text-align: center;
          }
        }
      }

      > footer {
        padding: 15px 0 0;
        display: flex;
        align-items: center;

        .update-field {
          margin: 0 auto 0 0;
          display: flex;
        }

        .total-amount {
          font-size: 24px;
          font-weight: 700;
        }
      }

      +.charity {
        margin: 15px 0 0;
      }
    }
  }
}
</style>