<template>
  <div class="user-selector">
    <div class="input" ref="input">
      <div :class="['user-selector-chip', {'has-user': result.type != 'PASTED'}, {'error' : result.error == 'NOT_FOUND'}]" v-for="(result, index) in results" :key="`results_${index}`" @click="deleteChip(index)">
        <template v-if="result.type == 'PASTED'">
          <fa-icon spin icon="sync" v-if="!result.error" />
          <fa-icon icon="exclamation-circle" v-else />
          <span>{{result.query}}</span>
        </template>
        <template v-else>
          <profile-picture height="18" :user="result" />
          <name :user="result" />
        </template>
      </div>

      <input ref="field" placeholder="Search by name or paste emails..." v-model="query" @paste="handlePaste" @keydown="handleKeyPress" @blur="handleBlur" @focus="handleFocus" />   
    </div>
    <portal to="userSelectorDropdown">
      <div v-if="showDropdown" id="user-selector-autocomplete" class="user-selector-autocomplete">
        <ul>
          <li v-for="user in autocompleteResults" :key="`acresult_${user.id}`">
            <a href="#" @click.prevent="handleAutocompleteSelection(user)">
              <profile-picture :user="user" />
              <name :user="user" />
            </a>
          </li>
        </ul>
      </div>
    </portal>

    <div v-html="autocompleteStyle"></div>
  </div>
</template>

<script>
export default {
  name: 'user-selector',
  computed: {
    showDropdown() {
      return (this.isFocused && !_.isEmpty(this.query) && this.autocompleteResults.length > 0) ? true : false;
    },
    autocompleteStyle() {
      return `<style>
      #user-selector-autocomplete {
        top: ${this.acStyle.top}px;
        left: ${this.acStyle.left}px;
        width: ${this.acStyle.width}px;
      }
      </style>
      `;
    },
    autocompleteResults() {
      return _.filter( this.searchResults, (u) => _.findIndex(this.results, {id: u.id}) === -1 )
    }
  },
  data() {
    return {
      searchResults: [],
      isFocused: false,
      query: '',
      results: [],
      getResDebounced: _.debounce( this.getAutocompleteResults, 500 ),
      acStyle: {}
    }
  },
  watch: {
    query(newVal, oldVal) {
      if( !_.isEmpty(newVal) ) {
        this.searchResults = [];
        this.getResDebounced();
      }
      else {
        this.searchResults = [];
      }
    },
    showDropdown(newVal, oldVal) {
      if( newVal === true ) {
        setTimeout( () => {
          this.positionDropdown();
        }, 10 );
      }
    },
    results(newVal, oldVal) {
      setTimeout( () => {
        this.positionDropdown();
      }, 10 );

      this.$emit('input', _.filter(newVal, v => ('id' in v)))
    }
  },
  mounted() {
    //this.$nextTick( this.positionDropdown );
  },
  methods: {
    handleBlur() {
     // this.isFocused = false;
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleAutocompleteSelection(user) {
      this.results.push( user )
      this.query = '';
      this.$refs.field.focus()
    },
    handlePaste(e) {
      const clipboard = e.clipboardData || window.clipboardData;
      const content = clipboard.getData('Text');

      let emails = _.map( content.split(/\s/g), e => e.toLowerCase() );
      emails = _.filter( emails, e => !_.isEmpty(e) );

      this.results = _.filter( this.results, c => {
        if( c.type == 'PASTED' && emails.indexOf( c.query ) > -1 ) return false;

        return true;
      } )

      this.results.push( ..._.map(emails, e => {
        return {
          type: 'PASTED',
          query: e
        };
      }) )

      this.resolveEmails(emails)

      setTimeout( () => {
        this.query = '';
      }, 10 )
    },
    async resolveEmails(emails) {
      const {results, notFound} = await this.$api.Users.resolve_emails( emails );

      const newResults = [];
      for( let i = 0; i < this.results.length; i ++ ) {
        const res = this.results[i];
        if( res.type != 'PASTED' ) {
          newResults.push(res)
          continue;
        };

        if( notFound.indexOf(res.query.toLowerCase()) > -1 ) {
          res.error = 'NOT_FOUND';
          newResults.push(res);
        }
        else {
          const found = _.find(results, (u) => u.email.toLowerCase() == res.query.toLowerCase())
          if( !found ) continue;
          if( _.findIndex( this.results, {id: found.id} ) > -1 ) {
            continue;
          }

          newResults.push(found);
        }
      }

      this.results = newResults;
    },
    deleteChip(i) {
      this.results.splice(i, 1);
    },
    handleKeyPress(e) {
      if( e.keyCode == 8 && _.isEmpty(this.query) ) {
        e.preventDefault();
        this.results.pop();
      }
    },
    positionDropdown() {
      const input = this.$refs.input;
      const loc = input.getBoundingClientRect();
      this.acStyle = {
        width: input.offsetWidth,
        top: (loc.top + input.offsetHeight),
        left: loc.left
      };
    },
    async getAutocompleteResults() {
      if( this.query.trim().length == 0 ) return;

      const results = await this.$api.Users.search( 1, this.query );

      this.searchResults = results;
    }
  }
}
</script>

<style lang="scss">
.user-selector {
  >.input {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    border: 1px solid #DCDFE6;
    border-radius: 4px;

    > input {
      min-width: 40%;
      flex: 1 1 auto;
      display: block;
      border: none;
      appearance: none;
      outline: none;
      height: 24px;
      font-size: 16px;
      padding: 0 5px;
      margin: 0;
    }
  }

  .user-selector-chip {
    display: block;
    position: relative;
    flex: 0 0 auto;
    width: auto;
    min-width: 0;
    box-shadow: rgba(0,0,0,0.1) 0 0 0 1px;
    border-radius: 3px;
    font-size: 14px;
    padding: 0px 5px 0px 20px;
    line-height: 24px;
    height: 24px;
    background: $background;
    color: $black;
    margin: 3px 3px;
    cursor: pointer;
    user-select: none;

    &.has-user {
      padding: 0px 5px 0px 30px;
    }

    svg {
      position: absolute;
      left: 5px;
      top: 6px;
      font-size: 12px;
      color: #747474;
    }

    &.error {
      background: rgba($red, 0.1);
      color: $red;

      svg {
        color: $red;
      }
    }

    .profile-picture {
      position: absolute;
      left: 5px;
      top: 3px;
    }
  }
}
</style>