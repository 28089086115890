<template>
  <div id="setup-wrap">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'setup-index',
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss">
#setup-wrap {
  > #view-wrap {
  }
}
</style>