<template>
  <div id="admin-wrap" class="container">
    <aside id="admin-sidebar">
      <router-link :to="{name: 'admin-dashboard'}" class="logo"></router-link>

      <nav>
        <ul>
          <li>
            <router-link :to="{name: 'admin-dashboard'}" exact>
              <fa-icon icon="home" />
              <span>Dashboard</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'admin-groups'}">
              <fa-icon icon="users" />
              <span>Groups</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'admin-users'}">
              <fa-icon icon="user" />
              <span>Users</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'admin-contributions'}">
              <fa-icon icon="hand-holding-usd" />
              <span>Contributions</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'admin-statements'}">
              <fa-icon icon="receipt" />
              <span>Statements</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'admin-settings'}">
              <fa-icon icon="cog" />
              <span>Settings</span>
            </router-link>
          </li>
        </ul>

        <div class="user-wrap">
          <profile-picture />
          <div>
            <name full />
            <a href="#" @click.prevent="logout">logout</a>
          </div>
        </div>
      </nav>

      <a href="http://help.givecupcake.com/" rel="noopener" target="_blank" class="support-btn"><fa-icon icon="life-ring" /> Need help?</a>

      <ul>
        <li>
          <a href="https://www.givecupcake.com/privacy-policy" rel="noopener" target="_blank">Privacy Policy</a>
        </li>
        <li>
          <a href="https://www.givecupcake.com/terms-of-service" rel="noopener" target="_blank">Terms of Service</a>
        </li>
      </ul>
    </aside>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'admin-index',
  components: {
    
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
      this.$router.replace({name: 'login'})
    }
  }
}
</script>

<style lang="scss" scoped>
#admin-wrap {
  padding: 0 15px 35px;
  #admin-sidebar {
    display: sticky;
    top: 0;
    flex: 0 0 280px;
    width: 100%;
    max-width: 280px;
    padding: 100px 0 0;
    position: relative;

    .logo {
      position: absolute;
      display: block;
      left: 0;
      width: 120px;
      top: 0;
      height: 100px;
      background-image: url('../../assets/half-logo.svg');
      background-size: auto 90px;
      background-position: left bottom;
      background-repeat: no-repeat;
      transform: translateY(9px);
      transition: transform 0.4s $curve;
      z-index: 0;

      &:hover {
        transform: translateY(0);
      }
    }

    .support-btn {
      display: block;
      text-align: center;
      margin: 25px 0 0;
      padding: 15px;
      background: #e7ded5;
      border-radius: 100em;
      color: $black;
      text-decoration: none;
    }

    .user-wrap {
      display: flex;
      align-items: center;
      margin: 0 0;
      padding: 15px 25px;
      border-top: 1px solid #e7ded5;

      > .profile-picture {
        margin: 0 10px 0 0;
      }

      .cupcake-name {
        display: block;
        line-height: 14px;
        font-size: 14px;

        &+a {
          font-size: 12px;
          line-height: 12px;
          color: $red;
          text-decoration: none;
          font-weight: 700;
        }
      }
    }

    > ul {
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      > li {
        padding: 4px 10px;
        display: block;
        > a {
          font-size: 14px;
          color: #797068;
          text-decoration: none;
        }
      }
    }

    > nav {
      display: block;
      background: $white;
      border-radius: $border-radius;
      min-height: 100px;
      box-shadow: $block-shadow;
      position: relative;
      z-index: 1;

      > ul {
        padding: 20px 0;
        margin: 0;
        
        > li {
          display: block;
          padding: 5px 20px;
          margin: 0;

          > a {
            display: flex;
            padding: 5px 10px;
            color: $black;
            text-decoration: none;
            font-size: 18px;
            align-items: center;

            > svg {
              display: block;
              font-size: 20px;
              flex: 0 0 45px;
              margin: 0 0 0 -10px;
              color: #cfc4b9;
            }

            > span {
              display: block;
              flex: 1;
            }

            &:hover {
              > span {
                font-weight: 700;
              }
            }

            &.router-link-active {
              > span {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
  > #view-wrap {
    padding: 0 35px;
  }
}
</style>