<template>
  <view-wrap>
    <header>
      <h1>Statements</h1>
    </header>

    <main>
      <template v-if="!!statements">
        <ul class="statement-list" v-if="statements.length > 0">
          <li v-for="s in statements" :key="`statement_${s.id}`">
            <router-link :to="{name: 'statement-details', params: {statementId: s.id}}">
              <h4>
                {{s.title}}
              </h4>

              <div class="status">
                <el-tooltip placement="top">
                  <div slot="content" v-html="statusText(s)"></div>
                  <fa-icon :icon="statusIcon(s)" />
                </el-tooltip>
              </div>

              <div class="amount">
                <span>{{$formatMoney(s.invoice_total/100, '0,0.00')}}</span>
              </div>

            </router-link>
          </li>
        </ul>
        <div class="empty-state" v-else>
          <h4>Nope, no statements yet.</h4>
          <p>No worries, we generate statements at the beginning of every month.</p>
        </div>
      </template>

      <loading-indicator color="dark" v-else />
    </main>
  </view-wrap>
</template>

<script>
export default {
  name: 'statements',
  head: {
    title: {
      inner: 'Statements'
    }
  },
  components: {
    
  },
  watch: {
     page() {
      this.populateStatements()
    }
  },
  data() {
    return {
      statements: null,
      page: 0,
      hasMorePages: true,
      populateStatements: _.debounce( this._populateStatements, 250 )
    }
  },
  mounted() {
    this.page = 1;

    this.$root.$on('scrolledToBottom', _.debounce(this._handleScrollBottom, 250) )
  },
  methods: {
    _handleScrollBottom() {
      if( this.hasMorePages ) this.page ++;
    },
    async _populateStatements() {
      const resp = await this.$api.Statements.list(this.page)

      if( this.statements === null ) this.statements = [];
      
      this.statements.push(...resp);

      if( resp.length < 50 ) this.hasMorePages = false;
    },
    statusText(c) {
      const {status} = c.statuses[0];
      switch( status ) {
        case "PENDING":
          return 'This statement is processing and will be ready to pay shortly.';
        break;
        case "FINALIZED":
          return `This statement is ready to pay.`;
        break;
        case "PAID":
          return `This statement has been paid in full.`;
        break;
      }
    },
    statusIcon(c) {
      const {status} = c.statuses[0];
      switch( status ) {
        case "PENDING":
          return 'clock';
        break;
        case "FINALIZED":
          return 'receipt';
        break;
        case "PAID":
          return 'check-circle';
        break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.statement-list {
  padding: 10px 0;
  margin: 0;
  display: block;

  > li {
    display: block;
    padding: 10px 0;
    margin: 0;

    > a {
      display: flex;
      background: $white;
      border-radius: $border-radius;
      box-shadow: $block-shadow;
      padding: 25px 25px 25px 25px;
      text-decoration: none;
      color: $black;
      align-items: center;
      transition: transform 0.2s $curve;

      &:hover {
        transform: scale(1.015);
      }

      &:after {
        content: '';
      }

      h4 {
        font-weight: 700;
        flex: 1;
        margin: 0 0 0 8px;

        > small {
          font-weight: normal;
          font-size: 0.8em;
          color: #616161;
        }
      }

      .amount {
       //margin: 0 15px;

        > span {
          background: $green;
          border-radius: 100em;
          padding: 4px 10px;
          display: block;
          font-size: 14px;
        }
      }

      .status {
        color: #616161;
        cursor: help;
        margin: 0 10px 0 0;

        &:hover {
          color: $black;
        }
      }

      .user-count {
        display: flex;
        align-items: center;

        > span {
          font-size: 14px;
          margin: 0 0 0 5px;
        }

        .user-preview {
          display: flex;
          padding: 0 6px;

          > .profile-picture {
            margin: 0 -3px;
            box-shadow: #fff 0 0 0 2px;
            flex: 0 0 22px;
          }
        }
      }
    }
  }
}
</style>