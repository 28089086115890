import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import api from '@/api';
import Router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    team: null,
    token: localStorage.getItem('cupcake_user_token') || null,
    groups: null,
    channels: null
  },
  mutations: {
    token(state, val) {
      if( !_.isEmpty(val) ) {
        state.token = val;
        localStorage.setItem('cupcake_user_token', val);
      }
      else {
        state.token = null;
        localStorage.removeItem('cupcake_user_token')
      }
    },
    team(state, val) {
      state.team = _.cloneDeep(val);
    },
    user(state, val) {
      state.user = _.cloneDeep(val);
    },
    groups(state, val) {
      state.groups = _.cloneDeep(val);
    },
    channels(state, val) {
      state.channels = _.cloneDeep(val);
    }
  },
  actions: {
    logout({commit}) {
      commit('token', null)
      commit('user', null)
      commit('team', null)
      commit('groups', null)
      commit('channels', null)
    },
    updateTeamSetting({commit}, s) {
      const settings = Object.assign(this.state.team.settings, s);
      commit('team', Object.assign(this.state.team, {
        settings
      }))
    },
    async populateUser({commit}) {
      await api.User.me().then((user) => {
        commit('team', user.team)
      
        delete user.team;
        commit('user', user);
      }).catch( e => {
        if(e.response.status === 400) {
          //force logout
          this.dispatch('logout');
          Router.replace({
            name: 'login',
            query: {
              continue: encodeURIComponent(Router.currentRoute.fullPath)
            }
          })
        }
      } )
    },
    async populateGroups({commit}) {
      const groups = await api.Groups.list();

      commit('groups', groups);
    },
    async populateChannels({commit}) {
      const channels = await api.Slack.get_channels();

      commit('channels', channels)
    },
  }
})
