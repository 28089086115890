<template>
  <div id="app">
    <router-view v-if="showView" />
    <div id="app-loader" v-else>
      <img src="./assets/ooo-she-thicc.svg" height="150">
      <h4>just a moment...</h4>
    </div>

    <template v-if="showView">
      <transition name="modal">
        <div v-if="routeModal !== null" class="modal-wrap" @click="closeModal">
          <component :is="routeModal" @close="closeModal({}, true)" />
        </div>
      </transition>

      <transition name="modal">
        <div v-if="dialog" class="modal-wrap" @click="closeDialog">
          <component :is="dialog.component" :data="dialog.data" @done="dialog.cb"></component>
        </div>
      </transition>

      <portal-target name="userSelectorDropdown"></portal-target>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    }
  },
  data() {
    return {
      showView: false,
      routeModal: null,
      dialog: null
    }
  },
  watch: {
    user(newVal, oldVal) {
      if( newVal !== null ) {
        this.showView = true;
        this.handleRouteGuard()
      }
    },
    token(newVal, oldVal) {
      if(newVal === null) {
        this.showView = true;
      }
    },
    '$route'(newVal, oldVal) {
      const matched = this.$route.matched;
      const route = matched[ matched.length - 1 ];
      
      if( 'components' in route && 'modal' in route.components ) {
        this.routeModal = route.components.modal;
      }
      else {
        this.routeModal = null;
      }
    }
  },
  created() {
    if( this.$store.state.token === null ) {
      this.showView = true;
    }
    else {
      this.$store.dispatch('populateUser');
    }

    this.$root.$on('openDialog', this.loadDialog)

    this.createScrollListener()
  },
  methods: {
    createScrollListener() {
      document.addEventListener('scroll', this.handleScroll)
    },
    handleScroll(e) {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.scrollHeight;

      if( bottomOfWindow ) {
        this.$root.$emit('scrolledToBottom')
      }
    },
    handleRouteGuard() {
      if( !this.user.is_admin && this.$route.matched[0].meta.isAdmin ) {
        this.$router.replace({
          name: 'member-home'
        })
      }
      else if( this.user.is_admin && this.$route.fullPath.indexOf('/member') > -1 ) {
        this.$router.replace({
          name: 'admin-dashboard'
        })
      }
    },
    closeModal(e, force = false) {
      if( e.target !== e.currentTarget && force == false ) return;

      this.$router.push('./')
    },
    loadDialog( modalComponent, modalData = {}, doneCallback ) {
      this.dialog = {
        component: modalComponent,
        data: Object.assign({}, modalData),
        cb: (d) => {
          this.dialog = null;
          doneCallback(d);
        }
      };
    },
    closeDialog(e, force = false) {
      if( e.target !== e.currentTarget && force == false ) return;

      this.dialog.cb(false);
      this.dialog = null;
    },
  }
}
</script>

<style lang="scss">
#app-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $background;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    transform: translate(-13px, 0);
  }

  h4 {
    font-size: 22px;
    margin: 15px 0 0;
  }
}

.modal-wrap.modal-enter-active {
  animation: fadeIn 0.3s $curve;

  >.modal-window {
    animation: modalIn 0.3s $curve;
  }

  >.modal-popup {
    animation: dialogIn 0.3s $curve;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50%, 100% {
    opacity: 1;
  }
}

@keyframes modalIn {
  0%, 30% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes dialogIn {
  0%, 30% {
    opacity: 0;
    transform: translateY(-100px) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
</style>