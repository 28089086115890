<template>
  <div id="view-wrap">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'view-wrap',
}
</script>