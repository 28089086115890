<template>
  <view-wrap>
    <header>
      <h1>Dashboard <small>{{greeting}}, <name />!</small></h1>
    </header>

    <main>
      <section class="get-started block" v-if="show_get_started">
        <header>
          <h2>Getting started</h2>

          <a href="#" class="close" @click.prevent="closeGetStarted"><fa-icon icon="times" /></a>
        </header>
        <div class="inner">
          <ul class="steps">
            <li :class="[{'done':team_settings.first_group === true}]">
              <h4>
                <i>1</i>
                <span>Setup your first group</span>
              </h4>
              <p>
                You'll need at least one group to start celebrating birthdays.
              </p>
              <router-link :to="{name: 'create-group'}" class="el-button el-button--primary is-round el-button--mini">Create a Group</router-link>
            </li>
            <li :class="[{'done':team_settings.first_birthday === true}]">
              <h4>
                <i>2</i>
                <span>Collect your first birthday.</span>
              </h4>
              <p>
                Once your group is ready, Cupcake will collect birthdays.
              </p>

              <p class="hint-text">(this happens automatically, nothing to do here!)</p>
            </li>
            <li>
              <h4>
                <i>3</i>
                <span>Customize your settings.</span>
              </h4>
              <p>
                Take a look at some settings you can customize.
              </p>
              <router-link :to="{name: 'admin-settings'}" class="el-button is-round el-button--mini">View settings</router-link>
            </li>
          </ul>
        </div>
      </section>

      <template v-if="stats !== null">
        <section class="latest-statement" v-if="stats.outstanding_statement !== null">
          <header>
            <h2>Donation Statement Due</h2>
          </header>

          <div class="inner">
            <p>
              <span>Your {{stats.outstanding_statement.title}} donation statement {{statement_time}}.</span>
              <router-link :to="{name: 'statement-details', params: {statementId: stats.outstanding_statement.id}}">View statement <fa-icon icon="long-arrow-right" /></router-link>
            </p>
          </div>
        </section>

        <section class="stats">
          <ul>
            <li>
              <div class="block">
                <h5>Month to date</h5>
                <div>
                  <h4>Donations</h4>
                  <h5>{{$formatMoney(stats.mtd_donations)}}</h5>
                </div>
                <div>
                  <h4>Birthdays</h4>
                  <h5>{{$formatNumber(stats.mtd_birthdays)}}</h5>
                </div>
              </div>
            </li>
            
            <li>
              <div class="block">
                <h5>Year to date</h5>
                <div>
                  <h4>Donations</h4>
                  <h5>{{$formatMoney(stats.ytd_donations)}}</h5>
                </div>
                <div>
                  <h4>Birthdays</h4>
                  <h5>{{$formatNumber(stats.ytd_birthdays)}}</h5>
                </div>
              </div>
            </li>
          </ul>
        </section>

        <section class="upcoming-birthdays">
          <header>
            <h2>Upcoming Birthdays</h2>
          </header>

          <div class="inner">
            <ul v-if="Object.keys(stats.upcoming_birthdays).length > 0">
              <li v-for="group in stats.upcoming_birthdays" :key="`ucgroup_${group.id}`">
                <h4><router-link :to="{name: 'group-details', params:{ groupId:group.id }}">{{group.name}}</router-link> <i>{{$formatMoney(group.reward_amount)}}/birthday</i></h4>
                <ul v-if="Object.keys(group.birthdays).length > 0">
                  <li v-for="day in Object.keys(group.birthdays)" :key="`upcoming_${day}`">
                    <h5>{{formatDate(day)}}</h5>
                    <ul>
                      <li v-for="birthday in group.birthdays[day]" :key="`ucbday_${birthday.id}`">
                        <profile-picture height="22" :user="birthday" />
                        <name :user="birthday" />
                      </li>
                    </ul>
                  </li>
                </ul>
                <p v-else class="no-bdays">No birthdays in the next 6 months.</p>
              </li>
            </ul>
            <p class="empty" v-else>
              Setup a group to see upcoming birthdays.
            </p>
          </div>
        </section>
      </template>
      <loading-indicator color="dark" v-else />
    </main>
  </view-wrap>
</template>

<script>
import {DateTime} from 'luxon'
export default {
  name: 'dashboard',
  head: {
    title: {
      inner: 'Dashboard'
    }
  },
  components: {
    
  },
  computed: {
    team_settings() {
      return this.$store.state.team.settings;
    },
    show_get_started() {
      return this.team_settings.show_get_started === false ? false : true;
    },
    statement_time() {
      const base = DateTime.utc()
      const due = DateTime.fromISO(this.stats.outstanding_statement.due_date)

      return `${due < base ? `was` : `is`} due ${due.toRelative({base})}`;
    }
  },
  data() {
    return {
      greeting: _.sample([
        'Howdy',
        'Hey',
        'Hello',
        'Hi',
        'Ahoy',
        'Greetings'
      ]),
      stats: null
    }
  },
  mounted() {
    this.populateDashboard()
  },
  methods: {
    formatDate(date) {
      return DateTime.fromISO( date ).toFormat( 'MMMM dd' );
    },
    async populateDashboard() {
      const resp = await this.$api.Team.get_dashboard()

      this.stats = resp;
    },
    async closeGetStarted() {
      this.$store.dispatch('updateTeamSetting', {show_get_started: false});
      await this.$api.Team.update_setting('show_get_started', false)
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  padding: 15px 0;

  > section {
    &+section {
      margin: 35px 0 0;
    }
  }

  .stats {
    ul {
      display: flex;
      margin: 0 -10px;

      > li {
        flex: 0 0 (100%/2);
        padding: 0 10px;

        >.block {
          background: $white;
          border-radius: $border-radius;
          box-shadow: $block-shadow;
          padding: 15px 15px 20px;
          text-align: center;
          display: flex;
          flex-wrap: wrap;

          >h5 {
            flex: 0 0 100%;
            margin: 0 0 15px;
            font-size: 14px;
            color: $red;
            font-weight: 700;
          }

          >div {
            flex: 1;

            &:last-child {
              border-left: 1px solid #dddddd;
            }

            > h4 {
              margin: 0 0 15px 0;
              font-weight: 700;

              > small {
                display: block;
                font-size: 12px;
                font-weight: normal;
                color: #5c5c5c;
              }
            }

            > h5 {
              font-size: 22px;
            }
          }
        }
      }
    }
  }

  .latest-statement {
    > header {
      margin: 0 0 15px;
      h2 {
        font-weight: 700;
        font-size: 18px;
      }
    }

    .inner {
      p {
        padding: 2px 0 2px 15px;
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 4px;
          background: #e7ded5;
          border-radius: 100em;
        }

        > span {
          display: block;
          margin: 0 0 5px;
        }

        a {
          color: $red;
          text-decoration: none;
          font-weight: 700;

          &:hover {
            color: darken($red, 20%);
          }
        }
      }
    }
  }

  .upcoming-birthdays {
    > header {
      margin: 0 0 15px;
      h2 {
        font-weight: 700;
        font-size: 18px;
      }
    }

    .inner {
      p.empty {
        padding: 2px 0 2px 15px;
        position: relative;
        color: #797068;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 4px;
          background: #e7ded5;
          border-radius: 100em;
        }
      }
      > ul {
        > li {
          padding: 2px 0 2px 15px;
          position: relative;

          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 4px;
            background: #e7ded5;
            border-radius: 100em;
          }

          > h4 {
            font-weight: 700;
            display: block;
            margin: 0 0 7px;

            >i {
              background: $green;
              border-radius: 100em;
              padding: 3px 7px;
              display: inline-block;
              font-size: 11px;
              line-height: 11px;
              font-weight: normal;
              vertical-align: 1px;
            }
            a {
              color: $red;
              text-decoration: none;

              &:hover {
                color: darken($red, 20%);
              }
            }
          }

          p.no-bdays {
            font-size: 14px;
            color: #797068;
          }

          &+li {
            margin: 20px 0 0;
          }

          > ul {
            display: flex;
            flex-wrap: wrap;
            > li {
              // list of days
              flex: 0 0 (100%/3);
              > h5 {
                font-weight: 700;
                font-size: 14px;
                margin: 0 0 5px;
              }

              > ul {
                > li {
                  display: flex;
                  align-items: center;
                  font-size: 14px;

                  .profile-picture {
                    margin: 0 5px 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  > section.block {
    background: $white;
    box-shadow: $block-shadow;
    border-radius: $border-radius;
    padding: 25px;

    > header {
      margin: -25px -25px 25px;
      padding: 25px;
      border-bottom: 1px solid #e7ded5;
      position: relative;
      height: 69px;

      .close {
        position: absolute;
        right: 0;
        top: 0;
        height: 69px;
        line-height: 69px;
        padding: 0 25px;
        text-decoration: none;
        color: #c5b9ad;

        &:hover {
          color: #a0968d;
        }
      }

      > h2 {
        font-weight: 700;
        font-size: 18px;
      }
    }
  }
}

.get-started {
  ul {
    display: flex;
    margin: -15px -15px;

    > li {
      display: block;
      padding: 15px;
      flex: 0 0 (100%/3);
      position: relative;

      > h4 {
        position: relative;
        padding: 7px 0 0 32px;
        font-weight: 700;
        font-size: 14px;
        min-height: 28px;
        margin: 0 0 10px;

        > i {
          display: block;
          position: absolute;
          left: 0;
          top: 3px;
          border-radius: 100%;
          background: $yellow;
          color: $black;
          font-weight: 700;
          text-align: center;
          height: 24px;
          width: 24px;
          line-height: 24px;
        }
      }

      p {
        line-height: 1.35em;
        margin: 0 0 15px;
        font-size: 14px;

        &.hint-text {
          font-size: 12px;
        }
      }

      a {
        text-decoration: none;
      }

      &.done {
        pointer-events: none;

        &:before {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!-- Font Awesome Pro 5.15.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath fill='%23e9525d' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'/%3E%3C/svg%3E");
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.7);
          z-index: 9;
          background-position: center 50px;
          background-size: 42px 42px;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.lead {
  font-size: 18px;
  font-weight: bold;
}
</style>