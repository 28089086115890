<template>
  <span class="cupcake-name">{{name}}</span>
</template>

<script>
export default {
  name: 'cupcake-name',
  props: {
    'user': Object, 
    'full': {
      type: Boolean,
      default: false
    }
  },
  computed: {
    me() {
      return this.$store.state.user;
    },
    name() {
      if( !!this.user ) {
        if( this.user.first_name ) return this.full !== false && this.user.last_name ? `${this.user.first_name} ${this.user.last_name}` : this.user.first_name;

        return this.user.display_name || this.user.email;
      }

      if( this.me.first_name ) return this.full !== false && this.me.last_name ? `${this.me.first_name} ${this.me.last_name}` : this.me.first_name;

      return this.me.display_name || this.me.email;
    }
  }
}
</script>