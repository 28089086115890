import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store'

import Admin from '../views/Admin'
import Dashboard from '../views/Admin/Dashboard'
import Groups from '../views/Admin/Groups'
import Users from '../views/Admin/Users'
import Settings from '../views/Admin/Settings'
import Login from '../views/Login'
import Contributions from '../views/Admin/Contributions'
import Donate from '../views/Donate'
import Statements from '../views/Admin/Statements'
import Disbursement from '../views/Disbursement'

import Member from '../views/Member'

import Setup from '../views/Setup'
import NotFound from '../views/NotFound'

import SlackOAuth from '../views/Setup/SlackOAuth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/disbursement',
    component: Disbursement
  },
  {
    path: '/',
    component: Admin,
    meta: {
      requiresAuth: true,
      isAdmin: true
    },
    children: [
      {
        path: '',
        name: 'admin-dashboard',
        component: Dashboard
      },
      {
        path: 'groups',
        component: Groups,
        children: [
          {
            path: '',
            name: 'admin-groups'
          },
          {
            path: 'create',
            name: 'create-group',
            components: {
              modal: () => import('@/components/Modals/Groups/GroupDetails')
            }
          },
          {
            path: ':groupId',
            name: 'group-details',
            components: {
              modal: () => import('@/components/Modals/Groups/GroupDetails')
            }
          }
        ]
      },
      {
        path: 'users',
        component: Users,
        children: [
          {
            path: '',
            name: 'admin-users'
          },
          {
            path: ':userId',
            name: 'user-details',
            components: {
              modal: () => import('@/components/Modals/Users/UserDetails')
            }
          }
        ]
      },
      {
        path: 'contributions',
        component: Contributions,
        children: [
          {
            path: '',
            name: 'admin-contributions'
          },
          {
            path: ':celebrationId',
            name: 'celebration-details',
            components: {
              modal: () => import('@/components/Modals/Celebrations/CelebrationDetails')
            }
          }
        ]
      },
      {
        path: 'statements',
        component: Statements,
        children: [
          {
            path: '',
            name: 'admin-statements'
          },
          {
            path: ':statementId',
            name: 'statement-details',
            components: {
              modal: () => import('@/components/Modals/Statements/StatementDetails')
            }
          }
        ]
      },
      {
        path: 'settings',
        name: 'admin-settings',
        component: Settings
      },
    ]
  },
  {
    path: '/member',
    component: Member,
    name: 'member-home',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/donate/:celebrationId',
    name: 'donate',
    component: Donate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/start',
    component: Setup,
    children: [
      {
        path: 'slack',
        name: 'setup-slack-oauth',
        component: SlackOAuth
      }
    ]
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeEach( (to, from, next) => {
  const parent = to.matched[0];
  const requiresAuth = _.some( to.matched, m => {
    if( 'meta' in m ) return !!m.meta.requiresAuth;

    return false;
  } )
  if( requiresAuth ) {
    if( !Store.state.token ) {
      return next({
        name: 'login',
        query: {
          continue: encodeURIComponent( to.fullPath )
        }
      });
    }
  }

  next();
} )

export default router
