import Vue from 'vue'

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

import App from './App.vue'
import router from './router'
import store from './store'
import numeral from 'numeral'
import objectPath from 'object-path';

Sentry.init({
  Vue,
  dsn: "https://2668529b2e084f9da987221fb0923959@o389293.ingest.sentry.io/5557151",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

import api from '@/api'
Vue.prototype.$api = api;

import VueHead from 'vue-head'
Vue.use(VueHead)

import ViewWrap from '@/components/ViewWrap'
Vue.component('view-wrap', ViewWrap)

import LoadingIndicator from '@/components/LoadingIndicator'
Vue.component('loading-indicator', LoadingIndicator)

import ProfilePicture from '@/components/ProfilePicture'
Vue.component('profile-picture', ProfilePicture)

import Name from '@/components/Name'
Vue.component('name', Name)

import UserSelector from '@/components/UserSelector'
Vue.component('user-selector', UserSelector)

import { Button, Select, Option, OptionGroup, RadioGroup, RadioButton, Alert, Message, Tooltip, Dropdown, DropdownMenu, Checkbox, Switch, MessageBox, Radio } from 'element-ui';

import './scss/app.scss'

Vue.prototype.$openDialog = function(component, data, callback) {
  this.$root.$emit('openDialog', component, data, callback)
};

Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(OptionGroup.name, OptionGroup);
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(RadioButton.name, RadioButton)
Vue.component(Alert.name, Alert)
Vue.component(Tooltip.name, Tooltip)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Switch.name, Switch)
Vue.component(Radio.name, Radio)

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faUsers, faHome, faPlus, faTimes, faCog, faUser, faSync, faExclamationCircle, faHandHoldingUsd, faClock, faPaperPlane, faReceipt, faAngleDown, faBirthdayCake, faCheckCircle, faLifeRing, faLongArrowRight, faEquals } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret)
library.add(faUsers)
library.add(faHome)
library.add(faPlus)
library.add(faTimes)
library.add(faCog)
library.add(faUser)
library.add(faSync)
library.add(faExclamationCircle)
library.add(faHandHoldingUsd)
library.add(faClock)
library.add(faPaperPlane)
library.add(faReceipt)
library.add(faAngleDown)
library.add(faBirthdayCake)
library.add(faCheckCircle)
library.add(faLifeRing)
library.add(faLongArrowRight)
library.add(faEquals)

Vue.component('fa-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.prototype.$formatNumber = (number) => {
  return numeral(number).format('0,0');
};

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import timezones from 'compact-timezone-list';
Vue.prototype.$timezones = timezones;

import getSymbolFromCurrency from 'currency-symbol-map'
Vue.prototype.$formatMoney = (amount, format='0,0', currency='USD') => {
  if( store.state.team ) currency = store.state.team.currency_code;

  return getSymbolFromCurrency(currency) + numeral(amount).format(format);
};

Vue.prototype.$fieldError = (_v) => {
  if( _v.$error ) return 'has-error';

  return '';
};

Vue.prototype.$fieldErrorMessage = (_v, _k, _msgs) => {
  const validations = Object.keys( _v ).reduce( (acc, k) => {
    if( k.substr(0, 1) != '$' ) {
      acc[k] = _v[k];
    }

    return acc;
  }, {} )

  const _defaultMessages = {
    required: `This field is required.`
  };

  for(const v in validations) {
    if( validations[v] === false ) return objectPath.get(_msgs, _k)[v] || _defaultMessages[v];
  }
};

Vue.prototype.$generateToken = function(len) {
  const dec2hex = function(dec) {
    return dec < 10
      ? '0' + String(dec)
      : dec.toString(16)
  };

  const arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
};

Vue.prototype.$formatEin = (_ein) => {
  return _ein.split(/(.{1,2})(.{2,})/).filter(Boolean).join('-');
};

Vue.prototype.$stripe = Stripe(process.env.VUE_APP_CUPCAKE_STRIPE);
Vue.prototype.$hithriveStripe = Stripe(process.env.VUE_APP_CUPCAKE_STRIPE, {stripeAccount: process.env.VUE_APP_HITHRIVE_STRIPE_ACCOUNT_ID});

import _ from 'lodash';
Vue.prototype._ = _;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
